import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Profanbau1 from "../components/profanbau1"
import Profanbau2 from "../components/profanbau2"
import Profanbau3 from "../components/profanbau3"
import Profanbau4 from "../components/profanbau4"

import "./index.css"

export default function ProfanbauPage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Profanbau"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Profanbau"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Profanbau</h1>
        <h2 className="referenceH2">
          Arabeskenmalerei, Orientalischer Pavillon im Garten der vier Ströme,
          Gärten der Welt, Berlin Marzahn
        </h2>
        <h3 className="referenceH3">
          Restaurierung, Ergänzung, Teilrekonstruktion
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Profanbau1">
              <Profanbau1 id="Profanbau1" />
              Pavillon nach der Fertigstellung
              <br />
              (Foto: Kersten Ullmann)
            </label>
            <label htmlFor="Profanbau2">
              <Profanbau2 id="Profanbau2" />
              Stratigrafie des austretenden Harzes an der Oberfläche
              <br />
              (Autorin Astrid Mendes)
            </label>
            <label htmlFor="Profanbau3">
              <Profanbau3 id="Profanbau3" />
              Vorzustand eines Segments aus dem Pavillon
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Profanbau4">
              <Profanbau4 id="Profanbau4" />
              Übersicht der Arbeitsschritte
              <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Der orientalische Pavillon wurde in Marokko aus Zedernholz nach
              historischen Vorbildern gefertigt und mit einer Zouak-Malerei
              gestaltet. Die Blüten- und Blattmotive entsprechen einer
              Arabeskenmalerei. Der Pavillon nimmt das Zentrum des Gartens ein.
              Die vier Wasserkanäle in dem angelegten Garten symbolisieren die
              Flüsse Pischon, Gihon, Tigris und Eufrat im Paradiesgarten Eden.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Eindringende Luftfeuchtigkeit durch einen sich darunter
              befindlichen Springbrunnen verknüpft, mit der Sonneneinstrahlung
              durch das aufgeheizte Blechdach, verursachten, dass Harze
              tränenförmig austraten und so Teile der Arabeskenmalerei
              zerstörten.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Wiederherstellung der Malerei im originalen Duktus mit einem
              stabilen Farbsystem.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Nach der Harzentfernung wurde eine Grundierung in mehreren Lagen
              mit schwedischem Leinöl aufgebracht. Anschließend konnte man das
              fehlende Muster mittels einer angefertigten Pause mit farbigen
              Leinöllasuren rekonstruieren.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">Berliner Senat.</p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Grün Berlin GmbH Dipl.-Ing. Hergen Stolle und Parkmanagerin Frau
              Beate Reuber.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
